import { render, staticRenderFns } from "./PostalTransactionSummaryReport.vue?vue&type=template&id=2fd3b491&scoped=true"
import script from "./PostalTransactionSummaryReport.vue?vue&type=script&lang=js"
export * from "./PostalTransactionSummaryReport.vue?vue&type=script&lang=js"
import style0 from "./PostalTransactionSummaryReport.vue?vue&type=style&index=0&id=2fd3b491&prod&lang=css"
import style1 from "./PostalTransactionSummaryReport.vue?vue&type=style&index=1&id=2fd3b491&prod&scoped=true&lang=css"
import style2 from "./PostalTransactionSummaryReport.vue?vue&type=style&index=2&id=2fd3b491&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd3b491",
  null
  
)

export default component.exports